<template>
  <div class="flex flex-col">
    <FullWaveFormVisualizer
      ref="wf"
      :file-id="fileId"
      :cue="cue"
      :lines="FullChat"
      @previous-fragment="previousFragment"
      @next-fragment="nextFragment"
      :not-allowed-next="notAllowed"
    />
    <div v-if="state !== 0" class="mt-12">
      <div>
        <div class="flex flex-col">
          <div class="flex w-full justify-center">
            <FillingFormFullAudio
              ref="filling-form"
              :lang="lang"
              :line-index="startAudio"
              :lines="FullChat"
              :state="canEdit"
              :view-mode="viewMode"
              :verifier-check-words="verifierCheckWords"
              @save:chat="saveChat"
              @not-allowed-changed="notAllowedChanged"
              @fragment-access-changed="fragmentAccessChanged"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullWaveFormVisualizer from "@/views/files/shared/components/FullWaveFormVisualizer.vue";
import { getTranscript, saveTranscript } from "@/api/files";
import FillingFormFullAudio from "@/views/files/shared/components/FillingFormFullAudio.vue";

export default {
  components: {
    FillingFormFullAudio,
    FullWaveFormVisualizer,
  },

  props: {
    fileId: {
      type: String,
      required: true,
    },
    meta: {
      type: Object,
    },
    lang: {
      type: String,
      required: true,
    },
    savedLines: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      startAudio: 1,
      cue: 0,
      FullChat: [],
      all: [],
      notAllowed: false,
    };
  },

  computed: {
    canEdit() {
      return this.state === 1 || this.state === 3;
    },
    viewMode() {
      return this.state === 5;
    },
    state() {
      return parseInt(this.$route.params.state);
    },
    user() {
      return this.$store.state.user.user;
    },
    verifierCheckWords() {
      return (
        this.canEdit &&
        this.user &&
        this.user.scopes &&
        this.user.scopes.includes("verifier")
      );
    },
  },
  watch: {
    FullChat: {
      immediate: true,
      handler(newLines) {
        if (newLines && newLines.length > 0) {
          this.cue = newLines[0].start;
        }
      },
    },
  },
  async mounted() {
    await this.getFullChat();
    if (this.FullChat[0] && this.FullChat[0].start) {
      this.cue = this.FullChat[0].start || 0;
    }
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
  },
  methods: {
    notAllowedChanged(notAllowed) {
      if (!this.viewMode) {
        this.notAllowed = notAllowed;
      }
    },
    fragmentAccessChanged(access) {
      if (!this.viewMode) {
        this.notAllowed = access;
        this.$emit("finish-button-access", access);
      }
    },
    async getFullChat() {
      try {
        const transcript = await getTranscript(this.fileId);
        this.FullChat = transcript.all || [];
      } catch (error) {
        console.error(error);
      }
      this.$emit("last-fragment", this.FullChat);
    },
    saveChat(text, skip, index) {
      // Update only the `text` property of the object at the given index
      const i = index - 1;
      this.FullChat[i].text = text || "";
      this.FullChat[i].skip = skip;

      // Save changes if needed
      this.save();
    },
    triggerSave() {
      if (this.$refs["filling-form"]) {
        this.$refs["filling-form"].triggerSaveChat();
      }
    },
    previousFragment(index) {
      if (this.canEdit) {
        const currentInput = this.$refs["filling-form"].getCurrentInput();
        this.saveChat(currentInput.text, currentInput.skip, currentInput.index);
      }
      this.startAudio = index;
      const i = index - 1;
      this.editIntervalText(i);
      this.cue = this.FullChat[index - 1].start;
      if (this.$refs["filling-form"]) {
        this.$refs["filling-form"].focusInput();
      }
      this.$emit("fragment-index-changes", this.startAudio);
    },
    nextFragment(index) {
      if (this.canEdit) {
        const currentInput = this.$refs["filling-form"].getCurrentInput();
        this.saveChat(currentInput.text, currentInput.skip, currentInput.index);
      }
      this.startAudio = index;
      const i = index - 1;
      this.editIntervalText(i);
      this.cue = this.FullChat[index - 1].start;
      if (this.$refs["filling-form"]) {
        this.$refs["filling-form"].focusInput();
      }
      this.$emit("fragment-index-changes", this.startAudio);
    },

    async save() {
      await saveTranscript(this.fileId, {
        lines: this.FullChat,
        channel: "all",
        fragment: this.startAudio,
      });
      this.$emit("save:changedLines", {
        payload: this.FullChat,
      });
    },

    editIntervalText(i) {
      if (!this.$refs["filling-form"] || !this.FullChat || !this.FullChat[i]) {
        console.warn(
          "Cannot edit interval text. Ensure refs and data are valid."
        );
        return;
      }

      const data = {
        text: this.FullChat[i].text || "",
        skip: this.FullChat[i].skip || false,
      };

      this.$refs["filling-form"].insertFormData(data);
    },
  },
};
</script>

<style></style>
